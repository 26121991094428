/* eslint-disable func-names */
import { effectResource } from 'src/services/effect.js';

const dropdown = document.querySelector('[data-tm-dropdown]');
const dropdownControl = document.querySelector('[data-tm-dropdown-control]');
const dropdownBoundary = document.querySelector('[data-tm-dropdown-boundary]');

if (dropdown) {
  document.addEventListener('click', function(e) {
    const isClickInside = dropdownBoundary.contains(e.target);
    const dropdownClosed = dropdown.classList.contains('hidden');
    if (!isClickInside && !dropdownClosed) {
      dropdown.classList.add('hidden');
      dropdownControl.classList.remove('reverted');
    }
  });

  dropdownControl.addEventListener('click', function() {
    dropdown.classList.toggle('hidden');
    dropdownControl.classList.toggle('reverted');
  });
}

function initMegaposts() {
  effectResource.getMegaposts().then(({ adverts }) => {
    if (!adverts.length) return;

    const [megapost] = adverts;
    if (!megapost) return;
    const { link, title, color } = megapost;
    let html = `
      <div class="tm-panel__bmenu_inner mq-not-mobile">
        <a
          href="${link.url}"
          class="tm-panel__bmenu tm-panel__slink"
          target="_blank"
          rel="noopener"
          style="color:${color}"
        >${title}</a>
    `;

    html += '</div>';

    document.querySelector('[data-megaposts]').insertAdjacentHTML('afterbegin', html);
  });
}

function initFeature() {
  effectResource.getFeature().then(({ adverts }) => {
    const featuredElement = document.querySelector('[data-featured]');
    if (!adverts.length) {
      featuredElement.dataset.featured = 'false';
      return;
    };

    const feature = adverts[0];
    const { link, title, image, gal: gaLabel } = feature;

    const featureLink = document.createElement('a');
    featureLink.href = link.url;
    featureLink.className = 'tm-panel__featured';
    if (gaLabel) {
      featureLink.addEventListener('click', () => {
        window.helpers.trackEvent('top_panel', 'feature', gaLabel);
      })
    }

    const linkHtml = `
      <img
          src="${image.url}"
          alt="${title}"
          class="tm-panel__featured-icon"
        >${title}
    `;

    featureLink.innerHTML = linkHtml;

    document.querySelector('[data-feature]').appendChild(featureLink);
    featuredElement.dataset.featured = 'true';
  });
}

initMegaposts();
initFeature();
