import 'swiper/scss';
import Swiper from 'swiper';
import { Navigation } from 'swiper/modules';

function initSwiper(el) {
  // eslint-disable-next-line no-new
  new Swiper(el, {
    modules: [Navigation],
    navigation: {
      nextEl: '[rel=next]',
      prevEl: '[rel=prev]',
    },
    slidesPerView: 'auto',
    noSwipingSelector: '[data-carousel-no-swipe]',
    breakpoints: {
      0: {
        slidesOffsetBefore: 8,
      },
      720: {
        slidesOffsetBefore: 96,
      },
      1200: {
        slidesOffsetBefore: document.body.offsetWidth / 2 - 294,
      },
    },
  });
}

Array.from(document.querySelectorAll('[data-carousel]')).forEach(initSwiper);
