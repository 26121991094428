document.addEventListener('click', (event) => {
  const tabName = event.target.dataset.tabButton;
  if (!tabName) return;

  const container = event.target.closest('[data-tabs]');
  const tabs = container.querySelectorAll('[data-tab], [data-tab-button]');
  Array.from(tabs).forEach((tab) => {
    tab.removeAttribute('data-active');
  });
  event.target.setAttribute('data-active', 'true');
  container
    .querySelector(`[data-tab="${tabName}"]`)
    .setAttribute('data-active', 'true');
});